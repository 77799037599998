<template>
  <div  class="px-2">
    <h1 class="text-3xl mb-6">{{msg}}</h1>
    <h2 class="text-xl mb-6" style="font-family:monospace">2025...</h2>
    <form>
      <div class="grid justify-center">
        <div class="w-full max-w-sm min-w-[200px]">
          <label class="block mb-3" for="email">Stay Updated</label>
          <div class="relative flex gap-3 md:flex-row flex-col">
            <input id="email" type="email" class="w-full min-w-64	bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md pl-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" placeholder="Email Address" />
            <button class="rounded bg-slate-800 py-1 px-2.5 text-center text-sm text-white transition-all">
              subscribe
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props : {
    msg:String
  }
}

</script>

<style scoped>

</style>